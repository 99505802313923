#login_container {
  label {
    font-weight: 400;
    color: #1c334a;
  }
  .form-control {
    border-radius: 0px;
    border: 1px solid #efefef;
    padding: 3px 5px;
    font-size: 14px;
    height: 32px;
  }
  .form-control:focus {
    background-color: #fff;
    outline: 0;
    box-shadow: none;
    border: 1px solid #efefef;
    border-bottom: 1px solid #007bff;
  }

}

.shri_text {
  display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-size: 34px;
    color: #183661;
}
