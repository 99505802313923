.al_navbar {
  .nav_background {
    // background-color: var(--color-14) !important;
   // padding: 15px 0px 15px 50px;
    background: #183661 !important;
  }
  .nav_brand {
   // border-right: 1px solid white;
    padding-right: 20px;
  }
  // .bg-dark {
  //   background-color: #d8d888 !important;
  //   box-shadow: 0 1px 2px 0 rgba(148, 150, 159, .3); // border-bottom: 1px solid #ccc;
  // }
  .nav_logo {
    width: 45px;
    height: auto;
    max-height: 45px;
    margin-right: 10px;
  }
  .nav_logo_text {
   // background: #2a2b2c !important;
   display: inline-block;
   font-weight: 500;
   font-size: 18px;
   color: white;
    // color: #178AC0;
    // color: var(--color-18) !important;
  }
  .al_login_text {
    color: #0a1612;
    cursor: pointer;
  }
  .nav_item {
    padding-right: 1rem;
    padding-left: 1rem;
    color: #fff;
    font-size: 14px;
    // font-size: 18px;
    // font-weight: lighter;
  }
  .nav_item a {
    text-decoration: none;
     //color: var(--color-17) ;
    //color: #f8f9fa !important;
     color:#f8fafa;
  }
  .active {
   // color: white !important;
  //  color: var(--color-18) !important;
  color:#ffff !important;
   font-size: 1rem;
   // font-size: 0.875rem;
    font-weight: 500;
    // border-top: 2px solid var(--color-18) !important;
    // border-bottom: 2px solid var(--color-18) !important;
     border-top: 2px solid #ffff !important;
    border-bottom: 2px solid #ffff !important;
    // color: black !important;
    // font-size: 0.875rem;
    // font-weight: 500;
    // border-top: 1px solid grey;
    // border-bottom: 1px solid grey;
  }
  .nav_dropdown_item {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 13px;
    font-weight: normal;
    border: 2px solid white;
    // border: 1px solid black;
    border-radius: 20px;
    &:focus {
      text-decoration: none;
    }
    .dropdown-toggle {
      text-decoration: none;
      color: white;
      // color: #373737;
    }
    .dropdown-menu {
      border: 0px solid rgba(255, 255, 255, 0.858);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 0px;
      padding: 0px;
      right: 7px !important;
      left: inherit;
      .dropdown-item {
        color: #373737;
        border-bottom: 1px solid #eeeeee;
        padding: 8px;
        font-size: 13px;
        font-weight: normal;
        cursor: pointer;
      }
      .dropdown-item:hover {
        background: #373737;
        color: #fff;
      }
      .border_bottom {
        border-bottom: 1px solid;
        color: #d3d3d3e8;
      }
    }
  }
}


.nav_link {
  font-style: normal;
  font-size: 16px;
  line-height: 170%;
  color: #ffffff;
  text-decoration: none !important;
  padding: 8px 8px 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav_dropdown_item {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 13px;
  font-weight: normal;
  border: 2px solid white;
  // border: 1px solid black;
  border-radius: 20px;

  &:focus {
    text-decoration: none;
  }

  .dropdown-toggle {
    text-decoration: none;
    color: white;
    // color: #373737;
  }

  .dropdown-menu {
    border: 0px solid rgba(255, 255, 255, 0.858);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    padding: 0px;
    right: 7px !important;
    left: inherit;

    .dropdown-item {
      color: #373737;
      border-bottom: 1px solid #eeeeee;
      padding: 8px;
      font-size: 13px;
      font-weight: normal;
      cursor: pointer;
    }

    .dropdown-item:hover {
      // background: #14213d ;
      background: #fb9c46 !important;
      color: #fff;
    }

    .border_bottom {
      border-bottom: 1px solid;
      color: #d3d3d3e8;
    }
  }
}

.nav_link_active {
  background: #F8F8F8;
  border-radius: 6px;
  color: #1C4B82 !important;
  font-weight: 500;
};
