#home {

  .carousel-indicators .active {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #dd6b4d !important;
    opacity: 1!important;
  }
  
  .carousel-indicators .disabled {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ebecec;
    opacity: 1!important;
  }
}

.last_updated {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 5px 10px;
  color: #fff;
  background: #444;
  font-size: 0.75rem;
}

.wrapper{
  height: 300px;
.quote_icon{
  color: gray;
  font-size: 38px;
  opacity: 0.3;
}
.user_icon{
  color: gray;
  font-size: 38px;
}
}

@media screen and (max-width: 400px) {
  .wrapper {
   height: 800px;
  }
}

