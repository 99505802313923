#al_holding {
  box-shadow: 5px 5px 40px -100px #888888;
  // .active {
  //   background: #a08f56;
  //   font-weight: bold;
  //   color: white
  // }
  .nav-link {
    padding: 1rem
  }
  .tab_view_title {
    font-size: 38;
    font-weight: 500;
    text-align: center;
    color: #000000;
    margin-top: 20px
  }
  .content {
    padding: 20px;
  }
  .rountTabContent {
    padding: 0px;
  }
  .header {
    display: flex;
    height: 400px;
    border-bottom: 2px solid rgba(255, 255, 255, 1);
  }
  // .row {
  //   margin: 20px
  // }
  .column {
    align-self: center;
  }
  .tableFooterButton {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: center;
    align-content: center
  }
  .subTab {
    display: flex;
  }

  .table_footer {
    font-size: 38;
    font-weight: 500;
    color: #ffffff;
    background-color: #3f87a6;
  }

  .holding_left_column {
    font-size: 38;
    font-weight: 500;
    color: #ffffff;
    background-color: #3f87a6;
  }

  .pie_chart {
    height: 450px;
  }

  .pie_chart_view {
    height: 600px;
  }
}
