.al_client {
  border-bottom: 2px solid #efefef;
  padding-bottom: 30px;
  margin-bottom: 30px;
  label {
    font-size: 24px;
  }
}

.table_input input {
  height: 30px;
  width: 110px;
  font-size: 14px;
}

//transaction
#trans_tabs {
  .btn {
    border-radius: 50%;
  }
  .btn-info {
    background-color: transparent;
    border: 0px solid #0a1612;
    color: #0a1612
  }
  .dropdown-menu {
    background: transparent;
    box-shadow: none;
    font-size: 0.8rem;
  }
  .dropdown-item {
    border-radius: 30px;
    color: #0a1612;
    font-weight: bold;
    margin-bottom: 1px;
    border: 1px solid #0a1612;
    background-color: white;
    cursor: pointer;
  }
  .tab_title {
    height: 38px;
    p {
      padding-top: .5em;
    }
  }
}

// purchase
#al_purchase {
  .al_heading {
    font-size: 20px;
    text-align: center;
    background-color: #3498db;
    padding: 10px;
    width: 100%;
    color: #fff;
  } // .al_amount {
  //   background: #BCEE70;
  //   color: #fff;
  // }
  // label {
  //   font-size: 24px;
  // }
  .al_or {
    font-size: 24px;
  }
  .al_search_box {
    position: relative;
  }
  .search_list {
    position: absolute;
    z-index: 5;
    background: #fff;
    top: 85px;
    width: 100%;
  }
  .search_list li {
    padding: 15px 10px;
    border: 1px solid rgba(202, 202, 202, 0.59);
    background-color: rgb(255, 255, 255);
  }
  .search_list li:hover {
    background-color: rgb(204, 204, 204);
    cursor: pointer;
  }
  .al_scheme_tabs {
    .nav-item {
      font-size: 12px;
      cursor: pointer; // border: 1px solid #0a1612;
      border-radius: 20px;
      margin-right: 10px
    }
    .nav-link {
      color: #0a1612;
    }
    .active {
      border: 1px solid #0a1612;
      color: black;
      border-radius: 20px;
    }
  }
}

#fresh {
  .card-body {
    padding: 0.8rem
  }
}

// redemption
#al_redemption {
  .al_heading {
    font-size: 20px;
    text-align: center;
    background-color: #3498db;
    padding: 10px;
    width: 100%;
    color: #fff;
  }
  .al_sub_heading {
    font-size: 24px;
    padding: 10px 20px;
  } // label {
  //   font-size: 16px;
  //   color: #CCC9D1;
  // }
  // .al_table thead th {
  //   border: 0px;
  //   color: #CCC9D1;
  //   font-weight: bold;
  //   font-size: 1rem;
  // }
  .al_edit {
    font-size: 24px;
    margin-right: 15px;
    cursor: pointer;
  }
  .al_cart {
    font-size: 24px;
    cursor: pointer;
  }
  .al_long_sort {
    font-size: 13px;
  }
  .al_long {
    margin-right: 35%;
  }
}

.al_modal_header {
  padding: 10px;
 // background: #74CEB7;
 //background: var(--color-17) !important;
 background: #275e7d !important;
  color: #fff;
}

.al_modal_body {
  label {
    font-size: 16px;
    color: #CCC9D1;
  }
  .al_amount {
    background: #BCEE70;
    color: #fff;
  }
  .al_scheme_text {
    margin-left: 10px;
    font-size: 16px;
  }
  .al_cart {
    font-size: 24px;
  }
  .al_close {
    font-size: 24px;
  }
}

// tabs
.al_tabs {
  .nav-item {
    font-size: 18px;
    font-weight: bold;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid lightgrey;
    background: linear-gradient(to top right, #669999 0%, #ffffff 100%);
  }
  .nav-link {
    color: #6c757d;
    cursor: pointer;
  }
  .active {
    border-bottom: 0px solid;
    color: #e2e6ec;
    border-radius: 20px;
    background: linear-gradient(90deg, #13cec5 0%, #0da9d0 100%);
  }
}

.input-error {
  display: inline-block;
  width: 100%;
  margin-top: .25rem;
  font-size: 12px;
  color: #dc3545;
}

// systematic
#systematic {
  .al_scheme_tabs_1 {
    .nav-item {
      font-size: 13px; // border-radius: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    .nav-link {
      color: #0a1612;
    }
    .active {
      font-size: 16px;
      color: #0a1612;
      background: white;
      font-weight: bold; // border-top:1px solid lightskyblue;
      // border-bottom:1px solid lightskyblue;
      border-radius: 20px;
      border: 1px solid #373737; // border-radius: 20px
      // border-radius: 20px;
    }
  }
  .al_scheme_tabs {
    .nav-item {
      font-size: 16px;
      cursor: pointer;
      border-top: 1px solid #373737;
      border-bottom: 1px solid #373737;
    }
    .nav-link {
      color: #0a1612;
    }
    .active {
      color: black;
      font-weight: 500;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      transition: box-shadow 0.3s ease-in-out;
      ; // background: lightgrey;
      border-top: 2px solid #373737;
      border-bottom: 2px solid #373737;
    }
    .nav-item:hover {
      border-top: 2px solid #373737;
      border-bottom: 2px solid #373737;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      transition: box-shadow 0.3s ease-in-out;
    }
  }
}
